

.settingsImage{
    color: white;
    width: 40px;
    height: 40px;
    stroke: none;
    opacity: 10%;
}
  
.settingsButton{
    background-color: transparent;
    color: #282c34;
    border: 0;
    display: inline-block;
    width: 50%
}

/* STYLES FOR THE TIMER RESET BUTTON/IMAGE */
.resetImage{
    width: 40px;
    height: 40px;
    stroke: none;
    color: white;
    opacity: 10%;
}
  
.resetImage:hover{
    opacity: 100%;
}
  
.resetButton{
    background-color: transparent;
    color: #282c34;
    border: 0;
    display: inline-block;
    width: 50%
}
/*******************************************/
  
.progressCircle{
    width: 30%;
}

.timerPage {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.pauseImage, .playImage{
    width: 40px;
    height: 40px;
    stroke: none;
    color: white;
    padding-top: 10px;
    opacity: 10%;
}

.pauseImage:hover, .playImage:hover{
    opacity: 100%
}

.pausePlayButton{
    background-color: transparent;
    color: #282c34;
    border: 0;
    display: inline-block;
    width: 50%
}
