.settingsImage{
  color: white;
  width: 40px;
  height: 40px;
  stroke: none;
  opacity: 10%;
}
  
.settingsButton{
  background-color: transparent;
  color: #282c34;
  border: 0;
  display: inline-block;
  width: 50%
}

.settingsPage{
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
  }

  .workSlider{
  height: 40px;
  border: 2px solid #3e98c7;
  border-radius: 26px;
  width: 300px;
  padding-left: -10px;
  padding-right: 10px;
}
  
  .workThumb{
  background-color: #3e98c7;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
  
  
  .breakSlider{
  height: 40px;
  border: 2px solid #C84630;
  border-radius: 26px;
  width: 300px;
  padding-right: 10px;
  padding-left: -10px;
}

  .breakThumb{
  background-color: #C84630;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 20px;    
}

  .breakSliderDiv{
  padding-top: 10px;
  padding-bottom: 10px;

}