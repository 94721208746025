.githubImage{
    width: 40px;
    height: 40px;
    stroke: none;
    color: white;
    opacity: 10%;
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0;
    padding-left: 20px;
    padding-bottom: 20px;
   
}



.githubImage:hover{
    opacity: 100%;
    
}




